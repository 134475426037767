<template>
  <div class="operate">
    <div class="line" />
    <h2>运营模式</h2>
    <div class="operate-contet">
      <!-- <h2>运营模式</h2> -->
      <p>
        山水物联网络货运平台通过对物流服务的提升及增值服务的赋能，面向货主企业、物流园区、物流公司、司机等主体获得更多的物流订单，逐步形成以平台发货、接单、运输、结算、增值服务为一体的线上交易模式。从而实现传统物流业务高效、优质、快速发展。
      </p>
      <div style=" text-align:center;"><img src="./../../assets/img/3.jpg" alt=""></div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "./../../assets/scss/base.scss";
.operate {
  .operate-contet {
    width: 100%;
    padding: 10px 60px 100px;
    box-sizing: border-box;
    background-color: #fff;
    margin-bottom: 200px;
    h2 {
      color: #003a8b;
      font-size: 30px;
      font-weight: bolder;
    }
    p {
      font-size: 20px;
      color: #333;
      line-height: 40px;
      margin: 70px 0 70px 0;
    }
  }
}
</style>
